<template>
  <div class="homeComponent" style = "background-color: rgb(var(--v-theme-background)); transition: background-color 0.3s ease; font-family: 'Poppins' !important">

    <!-- HEADER -->
    <BasicHeader />

    <!-- TITLE -->
    <v-row justify="center" style = " max-width:100%; background-color: rgb(var(--v-theme-background)); margin-top:10px; transition: background-color 0.3s ease;">
      <v-col cols="auto" class="text-center">
        <h1 style = "color: rgb(var(--v-theme-text));">Panda Podium Tools</h1>
      </v-col>
    </v-row>

    <!-- CONTENT -->
    <v-row justify="center" style = " width:100vw; max-width:100%; background-color: rgb(var(--v-theme-background)); margin-top:10px; transition: background-color 0.3s ease;">
      <v-col
        v-for="(page, i) in pages"
        :key="i"
        cols="11"
        md="3"
      >
        <v-card
          :disabled="loading"
          :loading="loading"
          class="mx-auto my-12"
          max-width="374"
        >
          <template v-slot:loader="{ isActive }">
            <v-progress-linear
              :active="isActive"
              color="deep-purple"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            height="250"
            :src = "page.imgLink"
            cover
          ></v-img>

          <v-card-item>
            <v-card-title>{{ page.title }}</v-card-title>
          </v-card-item>

          <v-card-text>
            {{ page.description }}
          </v-card-text>

          <!-- <v-divider class="mx-4 mb-1"></v-divider> -->


          <v-card-actions>
            <v-btn
              text="GO"
              block
              border
              @click="goToPage(page.href)"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */ 
import BasicHeader from '@/components/common/BasicHeader.vue';
import { useTheme } from 'vuetify'

export default {
  name: 'HomePage',
  setup(){
    const theme = useTheme();
  },
  components: {
    BasicHeader
  },
  props: {
    msg: String
  },
  data() {
    return {
      pages: [
        {title: "Wheelset Compare", description: "Compare wheelset data in Panda Podium.", href: "/wheelsetCompare", imgLink: '/images/common/WheelsetCompare.png'},
        {title: "Wheelset Finder", description: "Find the best wheelset for your bike.", href: "/wheelsetFinder", imgLink: '/images/common/WheelsetFinder.png'},
      ],
      isMobile: window.innerWidth <= 768,
    };
  },
  mounted() {
    // Update the document title when the component is mounted
    document.title = "PP Tools - Home Page";
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";
  },
  methods: {
    goToPage(href){
      this.$router.push(href);
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {box-sizing: border-box;}

.homeComponent { 
  box-sizing: border-box;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'Oswald', sans-serif;
  background-color: rgb(0, 0, 0);

  justify-items: center;
  grid-gap: 20px;
  height: 100vh;
  margin:0;
  padding:0;
  margin: auto;
}

.items{
  perspective: 1000;
  position: relative;
  width: 100px;
  height: 100px;
  /* background: green; */
}

.item{
  position: absolute;
  color:white;
  transform-style: preserve-3d;
  transition:all 0.5s all;
  background:yellow;
  text-align: center;
  width: 100px;
  height: 100px;
  background-color: black;
}

.item:hover  {
  transform: rotateY(180deg);
  /* box-shadow: -5px 5px 5px #aaa; */
}

.front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:#333;
}

img{
  height: 100%;
  width: 100%;
  /* margin-top: 40%; */
}

.word{
  margin-top:40%;
  color: white;
}

.back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background:black;
  color:white;
  transform:rotateY(180deg)
}

.header {
  overflow: hidden;
  background-color: black;
  padding: 20px 70px;
}

.header .logo {
  font-size: 25px;
  font-weight: bold;
  color: aliceblue;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
}

.footer {
  overflow: hidden;
  background-color: black;
  padding: 3px 7px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

#contactme{
  width: -10%;
  margin: 0 auto;
  bottom: -50%;
}
</style>