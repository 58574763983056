// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

// Define themes
const pandaPodiumDarkTheme = {
  dark: true,
  colors: {
    primary: '#1E88E5',      // Strong Blue
    secondary: '#D81B60',    // Pinkish Red
    accent: '#FFC107',       // Amber
    error: '#E53935',        // Bright Red
    success: '#43A047',      // Strong Green
    warning: '#FB8C00',      // Bright Orange
    info: '#29B6F6',         // Sky Blue
    background: '#1f1f1f',   // Almost Black
    surface: '#1E1E1E',      // Dark Gray
    textPrimary: '#E0E0E0',  // Light Gray
    textSecondary: '#BDBDBD', // Medium Gray
    button: '#3c4043',
    buttonHover: '#EA93E9',
    wheelResultBackground: '#373737',
    headerBackground: '#383838',
    cardBackground : '#a3a3a3',
  }
};

const pandaPodiumLightTheme = {
  dark: false,
  colors: {
    primary: '#4CAF50',      // Modern Green
    secondary: '#FFC107',    // Amber
    accent: '#FF5722',       // Deep Orange
    error: '#F44336',        // Red
    success: '#8BC34A',      // Light Green
    warning: '#FF9800',      // Orange
    info: '#03A9F4',         // Light Blue
    background: '#FFFFFF',   // Light Gray Background
    surface: '#FFFFFF',      // Pure White
    textPrimary: '#212121',  // Deep Gray
    textSecondary: '#757575', // Medium Gray
    button: '#FFFFFF',
    buttonHover: '#EA93E9',
    wheelResultBackground: '#e1e1e1',
    headerBackground: '#fc32f9',
    cardBackground: '#2fc7ed',
  }
};



export default createVuetify({
  theme: {
    defaultTheme: 'pandaPodiumLightTheme',
    themes: {
      pandaPodiumLightTheme: pandaPodiumLightTheme,
      pandaPodiumDarkTheme: pandaPodiumDarkTheme,
    },
  },
});
