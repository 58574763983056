<!-- PROBLEM -->
 <!-- WE HAVE WHEELS THAT ARE LESS THAN 20mm inner width -->
  <!-- RIM/DISC -->
   <!-- Wheels that doesn't have weight limit will automaticlly be excluded if fine tuned weight -->
    <!-- 18MM inner width -->

<template>
  <v-card  elevation="0" class="mx-auto mainCont" style="background:rgb(var(--v-theme-background));border-width: 0px; min-height: 100vh;border-radius:0px">

      <BasicHeader
          :icons="[{ icon: 'mdi-code-tags-check', event: 'dev-mode-on', text: 'dev mode' },]"
          @dev-mode-on="devModeOn"
      ></BasicHeader>

      <v-main class="main-content">
        <!-- DEV MODE INFO  -->
        <v-container v-if="devMode" class="text-center">
          <v-row>
            <v-col>
              <p style="color: red; font-weight: 600;">DEV MODE: </p>
              <!-- SCORE LIST -->
              <p>Score List: {{ scoreList }}</p>
            </v-col>
          </v-row>
        </v-container>

        <!-- TITLE -->
        <v-container class="text-center my-5 title-container" :class = "{ 'title-container-mobile': isMobile }">
          <v-row>
            <v-col class="title" v-show="!isMobile">
              <h1 style="font-weight:600">Panda Podium Wheelset Finder</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              
              <h2 :style="{ 'text-align': !isMobile ? 'justify' : 'center' }" style = "font-weight: 600;">
                Wheelset Finder
              </h2>

              <!-- MOBILE TOOL DESCRIPTION DIALOG -->
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-chip   v-show = "isMobile" v-bind="activatorProps" style ="margin-bottom: -30px;">
                    What is this tool?
                  </v-chip>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card>
                    <v-card-text>
                      What type of bike do you have? Do you ride often? Or are you training for a race? These are important questions to ask when you're trying to make a wheelset match. This tool will help you understand which wheelsets is right for you.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text="Close"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

            </v-col>
          </v-row>

          <v-row v-if = "!isMobile">
            <v-col>
              <p style="text-align: justify;">
                What type of bike do you have? Do you ride often? Or are you training for a race? These are important questions to ask when you're trying to make a wheelset match. This tool will help you understand which wheelsets is right for you.
              </p>
            </v-col>
          </v-row>

        </v-container>

        <!-- MAIN CONTENT -->
        <v-container class="main-content-container">
          <!-- QUESTIONS -->
          <v-container v-for="(question, key) in questions" :key="key" style = "padding:0px">
            <div v-if="key === currentQuestionKey || selectedOptions[key]" >
              <!-- DESKTOP QUESTIONS -->
              <v-row
                v-if = "!isMobile" 
                class="option-container section-row align-center" 
                style="flex-wrap: nowrap !important; display:flex; justify-content: center;"
              >

                <!-- QUESTION DESKTOP-->
                <v-col v-if = "!isMobile" cols="4" sm="3" class="section-title" style = "display: flex; justify-content: center;">
                  {{ question.title }}
                </v-col>
              
                <!-- ANSWERS -->
                <v-col cols="8" sm="9" class="options-container" style="column-gap:18px">

                  <div
                    v-for="(option, index) in question.options"
                    :key="index"
                    sm="5"
                    xs="5"
                    class="option-col"
                    style="overflow: hidden;"
                  >
                    <!-- With tooltip -->
                    <v-tooltip v-if="option.tooltipText" location="top">
                      <template v-slot:activator="{ props }">
                        <button
                          v-bind="props"
                          @click="selectOption(key, option)"
                          :class="{ 'selected': selectedOptions[key] === option.title }"
                          class = "option-btn"
                        >
                          <span
                            style="
                              display: block;
                              overflow-wrap: break-word;
                              word-wrap: break-word;
                            "
                          >
                            {{ option.title }}
                          </span>
                        </button>
                      </template>
                      <span>{{ option.tooltipText }}</span>
                    </v-tooltip>

                    <!-- Without tooltip -->
                    <button
                      v-else
                      @click="selectOption(key, option)"
                      :class="{ 'selected': selectedOptions[key] === option.title }"
                      class = "option-btn"
                    >
                      <span
                        style="
                          display: block;
                          overflow-wrap: break-word;
                          word-wrap: break-word;
                        "
                      >
                        {{ option.title }}
                      </span>
                    </button>
                  </div>

                </v-col>

              </v-row>

              <!-- MOBILE QUESTIONS -->
              <div
                v-else
                class="option-container section-row align-center" 
                style="flex-wrap: nowrap !important; display:flex; justify-content: center; flex-direction: column;"
              >

                <!-- QUESTION-->
                 <div style = "display: flex">
                  <div v-if = "question.mobileTooltipText" style = "margin-top: -2px">
                    <v-dialog max-width="500">
                      <template v-slot:activator="{ props: activatorProps }">
                          <v-icon v-bind="activatorProps">mdi-help-circle-outline</v-icon>
                      </template>

                      <template v-slot:default="{ isActive }">
                        <v-card>
                          <v-card-text v-html="question.mobileTooltipText">
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                              text="Close"
                              @click="isActive.value = false"
                            ></v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </div>

                  <div class="section-title" style = "display: flex; justify-content: center; font-weight: 800;">
                    {{ question.title }}
                  </div>
                </div>
                <!-- ANSWERS -->
                <div class="options-container" style="column-gap:18px; justify-content: center;">
                  <div
                    v-for="(option, index) in question.options"
                    :key="index"
                    sm="5"
                    xs="5"
                    class="option-col"
                    style="overflow: hidden;"
                  >
                    <!-- With tooltip -->
                    <v-tooltip v-if="option.tooltipText" location="top">
                      <template v-slot:activator="{ props }">
                        <button
                          v-bind="props"
                          @click="selectOption(key, option)"
                          :class="{ 'selected': selectedOptions[key] === option.title }"
                          class = "option-btn"
                          style = "margin:3px"
                        >
                          <span
                            style="
                              display: block;
                              overflow-wrap: break-word;
                              word-wrap: break-word;
                            "
                          >
                            {{ option.title }}
                          </span>
                        </button>
                      </template>
                      <span>{{ option.tooltipText }}</span>
                    </v-tooltip>

                    <!-- Without tooltip -->
                    <button
                      v-else
                      @click="selectOption(key, option)"
                      :class="{ 'selected': selectedOptions[key] === option.title }"
                      class = "option-btn"
                      style = "margin:3px"
                    >
                      <span
                        style="
                          display: block;
                          overflow-wrap: break-word;
                          word-wrap: break-word;
                        "
                      >
                        {{ option.title }}
                      </span>
                    </button>
                  </div>

                </div>

              </div>

            </div>
          </v-container>

          <!-- RESULTS -->
           <transition name = "fade">
            <v-container v-if="showResults" class="best-matches-section mt-1 section-row">
              <!-- LOADING ICON -->
              <!-- <v-row v-if="loading" class="text-center">
                <v-col>
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
              </v-row> -->

              <!-- IF FINISHED LOADING -->
              <template v-if = "true">
                <!-- PERFECT MATCHES CONTAINER -->
                <v-container v-if="showResults" style="padding-top:0px; padding-bottom: 0px;">

                  <!-- TITLE AND FINE-TUNE UI -->
                  <div class="text-center" style="margin-bottom:-6.5px">
        
                    <div><h3>We found these wheels for you</h3></div>
                    
                    <!-- FINE TUNE YOUR RESULTS   -->
                    <!-- @change="val => { if (val) console.log(stiffnessLightweightPref); }" -->
                    <div style = "display: flex; justify-content: center; margin-bottom: -40px;">

                      <!-- CHECKBOX (SLIDER) -->
                      <v-checkbox
                        v-model="sliderCheckbox"
                        label="fine tune your results"
                        color="EA93E9"
                        style = "margin-bottom: 20px;"
                      ></v-checkbox>
                    </div>

                    <!-- SLIDERS -->
                    <v-container v-show = "sliderCheckbox" style = "border : 1px solid rgb(var(--v-theme-wheelResultBackground)); border-radius: 20px; padding: 10px; margin-bottom: 20px;">
                      <div >
                      <!-- WEIGHT SLIDER -->
                      <div class="text-caption"  style = "margin-bottom: 10px;">Rider&Bike Weight Combined</div>
                        <v-slider
                          v-if = "!this.isMobile"
                          :disabled = "!sliderCheckbox"
                          label = "kg"
                          track-color="grey"
                          color="#EA93E9"
                          min = "40"
                          max = "200"
                          step = "1"
                          v-model = "weightCombined"
                          thumb-label="always"
                          thumb-color = "#EA93E9"
                        ></v-slider>

                        <v-slider
                          v-else
                          :disabled = "!sliderCheckbox"
                          label = "kg"
                          track-color="grey"
                          color="#EA93E9"
                          min = "40"
                          max = "200"
                          step = "1"
                          v-model = "weightCombined"
                          thumb-label="always"
                          thumb-size = "5"
                          thumb-color = "#EA93E9"
                          style = "margin-top: 15px;"
                        ></v-slider>

                      <div class="text-caption" style = "margin-bottom: 10px"> Stiffness/Lightweight preference</div>
                      <!-- STIFFNESS/LIGHTWEIGHT SLIDER -->
                        <v-slider
                          :disabled = "!sliderCheckbox"
                          v-model="stiffnessLightweightPref"
                          :max="3"
                          track-color="grey"
                          color="#EA93E9"
                          :ticks="tickLabels"
                          show-ticks="always"
                          step="1"
                          tick-size="3"
                          :thumb-size="isMobile ? 5 : 20"
                        ></v-slider>
                      </div>
                    </v-container>
                  </div>

                  <!-- DESKTOP WHEELSET LIST -->
                   <div v-if = "!isMobile">
                    <v-row
                      v-for="(wheel, index) in paginatedWheelsets"
                      :key="index"
                      class="match-row align-center"
                      style="background-color:rgb(var(--v-theme-wheelResultBackground)); background-clip: content-box; margin-bottom:10px; margin-top:0px; padding-top:0px; border-radius: 20px;"
                    >
                      <v-col cols="2">
                        <a :href="wheel.link" style = "display:flex">
                          <v-img
                            :src="wheel.imgLink || '/images/common/PPColoredIcon.jpeg'"
                            lazy-src="/images/common/PPColoredIcon.jpeg"
                            alt="Wheel image"
                            cover
                            aspect-ratio="1"
                            rounded = "xl"
                          ></v-img>
                        </a>
                      </v-col>
                      <v-col cols="9">
                        <p><strong>Name:</strong> {{ filterValue(wheel.name) }}</p>
                        <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                        <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                        <p><strong>Front Rim Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                        <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                        <p><strong>Front Rim Inner Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                        <!-- if weightCombined is set, show weight limit -->
                        <p v-if="weightCombined > 0"><strong>Rider Weight Limit(kg):</strong> {{ filterValue(wheel.riderWeightLimit) }}</p>
                      </v-col>
                      <v-col cols="1">
                        <v-btn :href="wheel.link" icon style="box-shadow: none; border: none;">
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- MOBILE WHEELSET LIST -->
                  <div v-else>
                    <v-row
                      v-for="(wheel, index) in paginatedWheelsets"
                      :key="index"
                      class="match-row align-center"
                      style="background-color:rgb(var(--v-theme-wheelResultBackground)); background-clip: content-box; margin-bottom:10px; margin-top:0px; padding-top:0px; border-radius: 20px;"
                    >
                      <v-col cols="5">
                        <a :href="wheel.link" style = "display:flex">
                          <v-img
                            :src="wheel.imgLink || 'https://via.placeholder.com/100'"
                            lazy-src="https://via.placeholder.com/100"
                            alt="Wheel image"
                            cover
                            aspect-ratio="1"
                            rounded = "xl"
                          ></v-img>
                        </a>
                      </v-col>
                      <v-col cols="7">
                        <p><strong>Name:</strong> {{ filterValue(wheel.name) }}</p>
                        <p><strong>Price:</strong> {{ formatPrice(wheel.price) }}</p>
                        <p><strong>Type:</strong> {{ filterValue(wheel.DiscOrRim) }}</p>
                        <p><strong>Depth(mm):</strong> {{ filterValue(wheel.frontRimDepth) }}</p>
                        <p><strong>Weight(g):</strong> {{ filterValue(wheel.weight) }}</p>
                        <p><strong>Width(mm):</strong> {{ filterValue(wheel.frontRimInnerWidth) }}</p>
                        <p v-if="weightCombined > 0"><strong>Rider Weight Limit(kg):</strong> {{ filterValue(wheel.riderWeightLimit) }}</p>
                      </v-col>
                      <div style = "position:absolute; ">
                        <v-btn :href="wheel.link" icon="mdi-link" size="x-small" style="box-shadow: none; border: none;"></v-btn>
                      </div>
                    </v-row>
                  </div>

                  <!-- LOAD MORE -->
                  <v-row v-if="(displayCount < displayWheelsets.length) && (this.selectedOptions.wheelsetType !== 'Gravel Wheels') " class="text-center mb-5" >
                    <v-col>
                      <v-btn @click="loadMore">Load More</v-btn>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- IF NO WHEELS ARE SHOWN -->
                <v-row v-if="displayWheelsets.length === 0" class="text-center">
                  <v-col>
                    <p>Sorry we couldn't find any wheels that meet your criteria</p>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </transition>
        </v-container>
      </v-main>

  </v-card>
</template>

<script>
import BasicHeader from '@/components/common/BasicHeader.vue';
import wheelsetService from '@/services/wheelsetService.js';
import { useTheme } from 'vuetify'

export default {
  name: 'WheelsetFinder',
  components: {
    BasicHeader
  },
  setup(){
    const theme = useTheme();

    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      theme.global.name.value = storedTheme;
    }

    const toggleTheme = () => {
      theme.global.name.value = theme.global.name.value === 'pandaPodiumLightTheme' ? 'pandaPodiumDarkTheme' : 'pandaPodiumLightTheme';
      localStorage.setItem('theme', theme.global.name.value);
    };

    return { toggleTheme };

  },  
  data() {
    return {
      devMode: false,
      theme: 'light',
      weightCombined: -1,
      stiffnessLightweightPref: 0,
      tickLabels: {
        0: 'N/A',
        1: 'lightweight',
        2: 'balanced',
        3: 'stiff',
      },
      questions: {
        wheelsetType: {
          title: "I'm looking for ...",
          options: [
            { title: "Road Wheels", attributes: null, nextQuestion: "iWantWheelsThat" },
            { title: "Gravel Wheels", attributes: null, nextQuestion: null } // No next question for Gravel Wheels
          ]
        },
        iWantWheelsThat: {
          title: "I want wheels that...",
          options: [
            { title: "Make my bike as fast as possible. Performance is everything", attributes: { rimDepth: 1, weight: 1, stiffness: 1 }, nextQuestion: "imTryingToGoFastOn" },
            { title: "Make my bike lighter / faster, but comfort is also important", attributes: { rimDepth: -1, stiffness: -1 }, nextQuestion: "imLookingFor" },
            { title: "Make my bike look cool. Performance isn't important", attributes: { lowPrice: 2 }, nextQuestion: "imLookingFor" }
          ]
        },
        imTryingToGoFastOn: {
          title: "I'm trying to go as fast as possible ...",
          options: [
            { title: "On the flats", attributes: { rimDepth: 2 }, nextQuestion: "roadSurfaces" },
            { title: "On steep climbs", attributes: { rimDepth: -2 }, nextQuestion: "roadSurfaces" },
            { title: "On rolling hills / mixed Terrain", attributes: { weight: 2 }, nextQuestion: "roadSurfaces" }
          ]
        },
        imLookingFor: {
          title: "I'm looking for ...",
          options: [
            { title: "Shallow wheels", attributes: { rimDepth: -2 }, nextQuestion: "roadSurfaces" },
            { title: "Medium depth wheels", attributes: { weight: 2 }, nextQuestion: "roadSurfaces" },
            { title: "Deep wheels", attributes: { rimDepth: 2 }, nextQuestion: "roadSurfaces" }
          ]
        },
        roadSurfaces: {
          title: "The road surfaces where I ride ...",
          options: [
            { title: "Are perfectly smooth, pristine tarmac", attributes: { lowInnerWidth: 1 }, nextQuestion: null, tooltipText: "25mm Tires (18mm-23mm inner width)" },
            { title: "Are mediocre. Not bad, Not excellent", attributes: { mediumInnerWidth: 1 }, nextQuestion: null, tooltipText: "28mm Tires (23mm-25mm inner width)" },
            { title: "Are terrible. Pot holes are everywhere and the surface is rough", attributes: { highInnerWidth: 1 }, nextQuestion: null, tooltipText: "32mm Tires (25mm-28mm inner width)" }
          ],
          mobileTooltipText: "Are perfectly smooth, pristine tarmac: 25mm Tires (18mm-23mm inner width), <br/><br/> Are mediocre. Not bad, Not excellent: 28mm Tires (23mm-25mm inner width)<br/><br/> Are terrible. Pot holes are everywhere and the surface is rough: 32mm Tires (25mm-28mm inner width)"
        }
      },
      currentQuestionKey: 'wheelsetType',
      selectedOptions: {},
      sections: [
        { title: 'Budget', icon: 'mdi-cash', options: ['$500-$800', '$800-$1200', '>$1200', 'No Preference'], selectedOption: null },
        { title: 'Bike Type', icon: 'mdi-bike', options: ['Road Rim', 'Road Disc', 'Gravel Disc', 'Mountain Disc'], selectedOption: null },
        { title: 'Terrain', icon: 'mdi-terrain', options: ['Flat', 'Hilly', 'Mixed'], selectedOption: null, tooltip: 'This Option will decide your rim depth.' },
      ],
      perfectMatch: [],
      bestMatches: [],
      jsonData: [],
      loading: false,
      showResults: false,
      isMobile: window.innerWidth <= 768,
      sliderCheckbox: false,
      wheelsets: [],
      displayWheelsets:[],
      addtionalLowPriceScore: 0,
      addtionalRimDepthScore: 0,
      addtionalWeightScore: 0,
      addtionalStiffnessScore: 0,
      displayCount: 3,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionKey];
    },
    themeClass() {
      return this.theme === 'dark' ? 'theme-dark' : 'theme-light';
    },
    themeIcon() {
      return this.theme === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night';
    },
    appBarColor() {
      return this.theme === 'dark' ? 'rgb(255 65 252 / 46%)' : '#fc32f9';
    },
    buttonStyle() {
      return this.theme === 'dark'
        ? { backgroundColor: '#333', color: '#fff', borderColor: '#fff' }
        : { backgroundColor: '#fff', color: '#000', borderColor: '#000' };
    },
    scoreList() {
      const scoreList = { lowPrice: 0, rimDepth: 0, weight: 0, stiffness: 0, lowInnerWidth: 0, mediumInnerWidth: 0, highInnerWidth: 0};
      for (const key in this.selectedOptions) {
        const selectedOption = this.questions[key].options.find(option => option.title === this.selectedOptions[key]);
        if (selectedOption && selectedOption.attributes) {
          for (const attr in selectedOption.attributes) {
            scoreList[attr] += selectedOption.attributes[attr];
          }
        }
      }
      scoreList.lowPrice += this.addtionalLowPriceScore;
      scoreList.rimDepth += this.addtionalRimDepthScore;
      scoreList.weight += this.addtionalWeightScore;
      scoreList.stiffness += this.addtionalStiffnessScore;

      return scoreList;
    },
    paginatedWheelsets() {
      if(this.selectedOptions.wheelsetType === "Gravel Wheels"){
        return this.displayWheelsets;
      }
      return this.displayWheelsets.slice(0, this.displayCount);
    }
  },
  methods: {
    loadMore() {
      this.displayCount += 3;
    },
    devModeOn(){
      this.devMode = !this.devMode;
    },
    selectOption(questionKey, option) {
      if (this.selectedOptions[questionKey] !== option.title) {
        this.removeSubsequentQuestions(questionKey);
      }
      this.selectedOptions[questionKey] = option.title;
      if (option.title === "Gravel Wheels") {
        this.removeAllButSpecificQuestion("wheelsetType");
        this.currentQuestionKey = null;

        this.generateResults();
        this.showResults = true;
      } else if (option.nextQuestion) {
        this.currentQuestionKey = option.nextQuestion;
      } 
      
      // RESULTS GENERATION
      else {
        this.generateResults();
        // console.log(this.displayWheelsets);
        this.showResults = true;
      }

      // console.log(this.selectedOptions);
    },
    removeSubsequentQuestions(questionKey) {
      this.showResults = false;
      const questionKeys = Object.keys(this.questions);
      const currentIndex = questionKeys.indexOf(questionKey);
      for (let i = currentIndex + 1; i < questionKeys.length; i++) {
        const key = questionKeys[i];
        if (this.selectedOptions[key]) {
          delete this.selectedOptions[key];
        }
      }

      // Scroll to the top of the page
      if(this.showResults && !this.isMobile) window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    removeAllButSpecificQuestion(specificQuestionKey) {
      const questionKeys = Object.keys(this.questions);
      for (const key of questionKeys) {
        if (key !== specificQuestionKey && this.selectedOptions[key]) {
          delete this.selectedOptions[key];
        }
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    numOfSelectedOptions() {
      return this.sections.reduce((acc, section) => acc + (section.selectedOption ? 1 : 0), 0);
    },
    generateResults(){
      if(this.wheelsets.length === 0) return;
      
      let tempWheelsets = this.wheelsets;
      // if weightCombined is set, then first run filterRiderWeight

      if(this.weightCombined > 0){
        tempWheelsets = this.filterRiderWeight(tempWheelsets);
      }

      if(this.stiffnessLightweightPref >= 0){
          this.addtionalWeightScore = 0;
          this.addtionalStiffnessScore = 0;
          this.addtionalRimDepthScore = 0;

        if(this.stiffnessLightweightPref === 1){
          this.addtionalWeightScore += 2;
        }
        else if(this.stiffnessLightweightPref === 2){
          this.addtionalWeightScore += 1;
          this.addtionalStiffnessScore += 1;
          this.addtionalRimDepthScore += 1;
        }
        else if(this.stiffnessLightweightPref === 3){
          this.addtionalStiffnessScore += 2;
        }
      }

      // GRAVEL WHEELS
      if(this.selectedOptions.wheelsetType === "Gravel Wheels"){
        // filter wheelsets with only frontRimInnerWidth >= 24
        this.displayWheelsets = tempWheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 24);
      }
      // ROAD WHEELS
      else{
        tempWheelsets = this.filterInnerWidth(tempWheelsets);
        this.displayWheelsets = this.sortWheelsets(this.scoreList, tempWheelsets);
      }

      // reset displayCount
      this.displayCount = 3;
    },
    filterRiderWeight(wheelsets){
      if(this.weightCombined <= 90){
        return wheelsets;
      }
      else{
        return wheelsets.filter(wheelset => wheelset.riderWeightLimit > this.weightCombined);
      }

    },
    // INNER WIDTH FILTER
    filterInnerWidth(wheelsets){
      const tireWidth = this.selectedOptions.roadSurfaces === "Are perfectly smooth, pristine tarmac" ? 25 : 
                        this.selectedOptions.roadSurfaces === "Are mediocre. Not bad, Not excellent" ? 28 : 32;

      if(tireWidth === 25){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 18 && wheelset.frontRimInnerWidth <= 23);
      }
      else if(tireWidth === 28){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 23 && wheelset.frontRimInnerWidth <= 25);
      }
      else if(tireWidth === 32){
        return wheelsets.filter(wheelset => wheelset.frontRimInnerWidth >= 25 && wheelset.frontRimInnerWidth <= 28);
      }
      else{
        return wheelsets;
      }
    },
    // MAIN SORTING 
    sortWheelsets(scoreList, wheelsets, debug = true) {
      const attributes = [
        { key: 'lowPrice', order: 'asc', getValue: (w) => w.price !== -1 ? w.price : Infinity },
        { key: 'rimDepth', order: 'desc', getValue: (w) => Math.min(w.frontRimDepth !== -1 ? w.frontRimDepth : 0, 60) },
        { key: 'weight', order: 'asc', getValue: (w) => w.weight !== -1 ? w.weight : Infinity },
        { key: 'stiffness', order: 'desc', getValue: (w) => w.stiffness !== -1 ? w.stiffness : 0 }
      ];

      // Sort attributes by their importance in descending order
      const sortedAttributes = attributes
        .filter(attr => scoreList[attr.key] !== 0)
        .sort((a, b) => Math.abs(scoreList[b.key]) - Math.abs(scoreList[a.key]));

      // set a variable based on number of sorted attributes, if 1 then 4, if 2 then 3, if 3 then 2
      let divisionIndex;
      if (sortedAttributes.length === 3) {
        divisionIndex = 2;
      } else if (sortedAttributes.length === 2) {
        divisionIndex = 3;
      } else if (sortedAttributes.length === 1) {
        divisionIndex = 4;
      } else {
        divisionIndex = 4;
      }

      if (debug) {
        console.log("------------DEBUG-----------");
        console.log("Sorted Attributes: ");
        sortedAttributes.forEach(attr => {
          console.log(attr.key + ": " + scoreList[attr.key] + " (" + attr.order + ")");
        });
        console.log("divisionIndex: " + divisionIndex);
        console.log("Each attribute will leave " + 100/divisionIndex + "% of the wheelsets");
        console.log("Beginning with " + wheelsets.length + " wheelsets");
      }



      let remainingWheelsets = wheelsets;

      sortedAttributes.forEach(attr => {
        remainingWheelsets = remainingWheelsets.sort((a, b) => {
          const valueA = attr.getValue(a);
          const valueB = attr.getValue(b);
          const order = scoreList[attr.key] > 0 ? attr.order : (attr.order === 'asc' ? 'desc' : 'asc');
          return order === 'asc' ? valueA - valueB : valueB - valueA;
        });

        let filteredWheelsetLength = Math.ceil(remainingWheelsets.length / divisionIndex);
        if(filteredWheelsetLength < 3){
          console.log("filteredWheelsetLength is less than 3, returning remaining wheelsets");
          return remainingWheelsets;
        }
        remainingWheelsets = remainingWheelsets.slice(0, filteredWheelsetLength);

        if (debug) {
          console.log(`Sorting by ${attr.key}, leaving ${remainingWheelsets.length} wheelsets:`);
          remainingWheelsets.forEach((wheelset, index) => {
            console.log(`${index + 1}. ${wheelset.name} (${attr.key}: ${attr.getValue(wheelset)})`);
          });
        }


      });

      return remainingWheelsets;
    },
    filterValue(value) {
      return value === -1 || value === '-1' ? 'N/A' : value;
    },
    formatPrice(price) {
      return price === -1 || price === '-1' ? 'N/A' : `$${price}`;
    },
  },
  async mounted() {
    document.title = "PP Wheelset Finder";

    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";

    try {
        const response = await wheelsetService.getAllWheelsets()
        this.wheelsets = response.data.data.results;

        // filter out wheelsets with no spokeType (spokeType = "")
        this.wheelsets = this.wheelsets.filter(wheelset => wheelset.spokeType !== "" && wheelset.spokeType !== "Carbon Tri-Spoke");

        // filter mtb wheelsets (frontRimOutterWidth > 34)
        this.wheelsets = this.wheelsets.filter(wheelset => wheelset.frontRimOutterWidth <= 34);

    } catch (error) {
        console.error('Error fetching JSON:', error);
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    weightCombined: function(){
      this.generateResults();
      // this.fineTunedWheelsets = this.fineTunedWheelsets.filter(wheelset => wheelset.riderWeightLimit <= val);
    },

    stiffnessLightweightPref: function(){
      this.generateResults();
    }
  },
};
</script>

<style scoped>
  .option-section {
    margin-bottom: 20px;
  }

  .section-row {
    margin-bottom: 10px;
  }

  .section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    display: flex;
    align-items: center;
  }

  .options-container {
    display: flex;
    flex-wrap: wrap;
  }


  .selected {
    background-color: rgb(var(--v-theme-buttonHover)) !important; 
    color: rgb(var(--v-theme-textPrimary)) !important;
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.option-btn {
  margin: 5px;
  white-space: normal;
  word-break: break-word;
  text-align: center;
  width: auto;
  background: rgb(var(--v-theme-button));
  color: rgb(var(--v-theme-textPrimary));
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-btn:hover {
  background: rgb(var(--v-theme-buttonHover));
}

.title-container-mobile{
  padding: 0px;
}

:deep(.v-slider-thumb__label) {
    background-color: #EA83E9 !important;
}

</style>